<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>Mailroom Reports</v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="tab">
      <v-tab>Chart</v-tab>
      <v-tab>Table</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select v-model="period" :items="periodOptions" label="Report Period" outlined></v-select>
              </v-col>
              <v-col>
                <v-select v-if="period === 'Month' || period === 'Week'" v-model="selectedYears" :items="years" label="Year Select" multiple outlined chips small-chips>
                  <template v-slot:prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="toggleYearSelectAll">
                      <v-list-item-action>
                        <v-icon>
                          {{ selectedYears.length === years.length ? 'fas fa-times-square' : (selectedYears.length === 0 ? 'fal fa-square' : 'fas fa-minus-square') }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All Years</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0" small>{{ item.text }}</v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">(+{{ selectedYears.length - 1 }} others)</span>
                  </template>
                </v-select>
                <v-select v-else v-model="selectedTerms" :items="terms" label="Term Select" multiple outlined chips small-chips>
                  <template v-slot:prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="toggleTermSelectAll">
                      <v-list-item-action>
                        <v-icon>
                          {{ selectedTerms.length === terms.length ? 'fas fa-times-square' : (selectedTerms.length === 0 ? 'fal fa-square' : 'fas fa-minus-square') }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All Terms</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0" small>{{ item.text }}</v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">(+{{ selectedTerms.length - 1 }} others)</span>
                  </template>
                </v-select>
              </v-col>
              <v-col v-if="period === 'Day' || period === 'Hour'">
                <v-select v-model="selectedWeeks" :items="weekOptions" label="Term Weeks" :hint="'Current week: '" multiple outlined>
                  <template v-slot:prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="toggleWeekSelectAll">
                      <v-list-item-action>
                        <v-icon>
                          {{ selectedTerms.length === terms.length ? 'fas fa-times-square' : (selectedTerms.length === 0 ? 'fal fa-square' : 'fas fa-minus-square') }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All Weeks</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0" small>{{ item.text }}</v-chip>
                    <span v-if="index === 1" class="grey--text text-caption">(+{{ selectedWeeks.length - 1 }} others)</span>
                  </template>
                </v-select>
              </v-col>
              <v-col>
                <v-select v-model="reportType" :items="reportOptions" label="Report Type" outlined>
                  <template v-slot:append-outer>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon :disabled="reportOptions === '' || periodOptions === ''" @click="runReport" style="margin-top:-6px">
                          <v-icon>fal fa-running</v-icon>
                        </v-btn>
                      </template>
                      <span>Run Report with Selected Options</span>
                    </v-tooltip>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="period === 'Day' || period === 'Hour'" class="mt-0">
              <v-col class="pt-0 mt-0">
                <v-switch v-model="average" label="Show Average" hide-detail class="mt-0">
                  <template v-slot:prepend>
                    <label class="v-label" style="color:rgba(255, 255, 255, 0.7);width:130px">Show Sum (Total)</label>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col :cols="12" md="10" offset-md="1" lg="8" offset-lg="2" xl="6" offset-xl="3">
            <v-card class="mt-3">
              <v-card-text v-if="loading" class="pa-10">
                <h3>Loading...</h3>
              </v-card-text>
              <v-card-text v-else>
                <LineChart :chartData="chartData" :options="chartOptions" :height="60" :width="80"></LineChart>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item>
        <v-data-table :headers="tableHeaders"></v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { ref, reactive, onMounted, watch } from '@vue/composition-api'
import { Line } from 'vue-chartjs/legacy'
import { getWeekOfYear } from '@/helpers/functions'

export default {
  components: {
    LineChart: Line
  },
  setup (props, { root }) {
    const tab = ref(0)
    const width = ref(800)
    const height = ref(400)
    const chartOptions = reactive({
      responsive: true,
      plugins: {
        legend: {
          position: 'top'
        },
        title: {
          display: true,
          text: 'Package Trends'
        }
      }
    })
    const colors = ref([])
    const chartData = reactive({
      labels: [],
      datasets: []
    })
    const loading = ref(true)
    function loadTerms () {
      const aggregate = [
        { $unwind: '$history' },
        { $match: { 'history.status': 'Received' } },
        {
          $group: {
            _id: {
              year: { $year: '$history.date' },
              term: { $cond: [ { $lte: [{ $month: '$history.date' }, 6] }, '05', '60' ] }
            },
            count: { $sum: 1 }
          }
        },
        { $sort: { '_id.year': 1, '_id.term': 1 } }
      ]
      root.$feathers.service('mailroom/package').find({ query: { aggregate } }).then(({ data }) => {
        chartData.data = data.map(({ _id: { year, term }, count }) => {
          const label = (term === '05' ? 'Spring ' : 'Fall ') + year
          terms.value.push({ text: label, value: year + term })
          return { label, value: count }
        })
        terms.value.reverse()
      })
    }
    function loadYears () {
      const aggregate = [
        { $unwind: '$history' },
        { $match: { 'history.status': 'Received' } },
        {
          $group: {
            _id: {
              year: { $year: '$history.date' }
            },
            count: { $sum: 1 }
          }
        },
        { $sort: { '_id.year': 1 } }
      ]
      root.$feathers.service('mailroom/package').find({ query: { aggregate } }).then(({ data }) => {
        chartData.data = data.map(({ _id: { year }, count }) => {
          years.value.push({ text: year, value: year })
          return { label: year, value: count }
        })
        years.value.reverse()
      })
    }
    onMounted(() => {
      // 16711680 is red (FF0000)
      for (let i = 0; i < 50; i++) {
        const color = 16711680 - (i * 505050)
        colors.value.push('#' + ('000000' + color.toString(16)).slice(-6))
      }
      loadTerms()
      loadYears()
      runReport()
    })
    const period = ref('Month')
    const periodOptions = ref([
      { text: 'By Month of Year', value: 'Month' },
      { text: 'By Month of Term', value: 'RelMonth' },
      { text: 'By Week of Year', value: 'Week' },
      { text: 'By Week of Term', value: 'RelWeek' },
      { text: 'By Day of Week', value: 'Day' },
      { text: 'By Hour', value: 'Hour' }
    ])

    const terms = ref([])
    const selectedTerms = ref([])
    function toggleTermSelectAll () {
      if (selectedTerms.value.length === terms.value.length) {
        selectedTerms.value = []
      } else {
        selectedTerms.value = terms.value.map(({ value }) => value)
      }
    }

    const years = ref([])
    const selectedYears = ref([])
    function toggleYearSelectAll () {
      if (selectedTerms.value.length === terms.value.length) {
        selectedTerms.value = []
      } else {
        selectedTerms.value = terms.value.map(({ value }) => value)
      }
    }

    const selectedWeeks = ref('')
    const weekOptions = ref([])
    for (let i = 1; i <= 16; i++) {
      weekOptions.value.push({ text: 'Week ' + i, value: i })
    }
    function toggleWeekSelectAll () {
      if (selectedWeeks.value.length === weekOptions.value.length) {
        selectedWeeks.value = []
      } else {
        selectedWeeks.value = weekOptions.value.map(({ value }) => value)
      }
    }

    const chartType = ref('line')
    const reportType = ref('Received')
    const reportOptions = ref([
      { text: 'Packages Received', value: 'Received' },
      { text: 'Package Picked-Up', value: 'Picked Up' }
    ])

    async function runReport () {
      loading.value = true
      const aggregate = [
        { $unwind: '$history' },
        { $match: { 'history.status': reportType.value } }
      ]
      const $group = {
        _id: {
          year: { $year: '$history.date' },
          term: { $cond: [ { $lte: [{ $month: '$history.date' }, 6] }, '05', '60' ] }
        },
        count: { $sum: 1 }
      }
      const terms = []
      const query = {}
      if (selectedTerms.value.length > 0) {
        query.term = { $in: selectedTerms.value }
      } else {
        query.term = { $gte: '201660' }
        query.type = 'Traditional'
      }
      const { data: termData } = await root.$feathers.service('system/term').find({ query })
      for (const { term, start, end } of termData) {
        terms.push({ term, start: new Date(start), end: new Date(end), startWeek: getWeekOfYear(start), endWeek: getWeekOfYear(end) })
      }
      let $secondGroup = false
      const $sort = { '_id.year': 1 }
      const $match = {}
      switch (period.value) {
        case 'Month':
          delete $group._id.term
          $group._id['month'] = { $month: '$history.date' }
          $sort['_id.month'] = 1
          if (selectedYears.value.length > 0) {
            $match['_id.year'] = { $in: selectedYears.value.map((year) => parseInt(year)) }
          }
          break
        case 'RelMonth':
          $group._id['month'] = { $month: '$history.date' }
          $sort['_id.term'] = 1
          $sort['_id.month'] = 1
          $match.$or = terms.map(({ term, start, end }) => {
            return {
              '_id.year': start.getFullYear(),
              '_id.term': term.substring(4),
              '_id.month': { $gte: start.getMonth() + 1, $lte: end.getMonth() + 1 }
            }
          })
          break
        case 'RelWeek':
          $group._id['week'] = { $week: '$history.date' }
          $sort['_id.term'] = 1
          $sort['_id.week'] = 1
          $match.$or = terms.map(({ term, start, startWeek, endWeek }) => {
            return {
              '_id.year': start.getFullYear(),
              '_id.term': term.substring(4),
              '_id.week': { $gte: startWeek, $lte: endWeek }
            }
          })
          break
        case 'Week':
          delete $group._id.term
          $group._id['week'] = { $week: '$history.date' }
          $sort['_id.week'] = 1
          if (selectedYears.value.length > 0) {
            $match['_id.year'] = { $in: selectedYears.value.map((year) => parseInt(year)) }
          }
          break
        case 'Day':
          $group._id['week'] = { $week: '$history.date' }
          $group._id['date'] = { $dateToString: { format: '%Y-%m-%d', date: '$history.date' } }
          $sort['_id.term'] = 1
          $sort['_id.day'] = 1
          if (terms.length > 0) {
            $match.$or = terms.map(({ term, startWeek, endWeek }) => {
              const obj = {
                '_id.year': parseInt(term.substring(0, 4)),
                '_id.term': term.substring(4)
              }
              if (selectedWeeks.value.length > 0) {
                obj['_id.week'] = { $in: selectedWeeks.value.map((val) => startWeek - (val - 1)) }
              } else {
                obj['_id.week'] = { $gte: startWeek, $lte: endWeek }
              }
              return obj
            })
          }
          $secondGroup = {
            _id: {
              year: '$_id.year',
              term: '$_id.term',
              day: { $dayOfWeek: { $dateFromString: { dateString: '$_id.date' } } }
            },
            count: { $sum: '$count' },
            avg: { $avg: '$count' }
          }
          break
        case 'Hour':
          $group._id['month'] = { $month: '$history.date' }
          $group._id['hour'] = { $hour: '$history.date' }
          $sort['_id.term'] = 1
          $sort['_id.hour'] = 1
          break
      }
      aggregate.push({ $group })
      if (JSON.stringify($match) !== '{}') aggregate.push({ $match })
      if ($secondGroup !== false) aggregate.push({ $group: $secondGroup })
      aggregate.push({ $sort })
      root.$feathers.service('mailroom/package').find({ query: { aggregate } }).then(({ data }) => {
        data = data.filter(({ _id: { year, term } }) => {
          for (let i = 0; i < selectedTerms.value.length; i++) {
            if (selectedTerms.value[i] === year + term) return true
          }
          return selectedTerms.value.length === 0
        })
        chartData.datasets = []
        const baseData = []
        let colorIndex = 0
        const hashByTerm = {}
        switch (period.value) {
          case 'Month':
            chartData.labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
            data.forEach(({ _id: { year, term, month, week, day, hour }, count }) => {
              const label = year
              if (!(label in hashByTerm)) hashByTerm[label] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
              const index = month - 1
              hashByTerm[label][index] = count
            })
            for (const label in hashByTerm) {
              chartData.datasets.push({ label, data: hashByTerm[label], borderColor: colors.value[colorIndex] })
              colorIndex++
            }
            break
          case 'RelMonth':
            chartData.labels = ['Month 1 (Jan/Aug)', 'Month 2 (Feb/Sep)', 'Month 3 (Mar/Oct)', 'Month 4 (Apr/Nov)', 'Month 5 (May/Dec)']
            data.forEach(({ _id: { year, term, month, week, day, hour }, count }) => {
              const label = (term === '05' ? 'Spring ' : 'Fall ') + year
              if (!(label in hashByTerm)) hashByTerm[label] = [0, 0, 0, 0, 0]
              let index = month
              for (const { term: termCode, start } of terms) {
                if (termCode === year + term) {
                  index -= start.getMonth() + 1
                }
              }
              hashByTerm[label][index] = count
            })
            for (const label in hashByTerm) {
              chartData.datasets.push({ label, data: hashByTerm[label], borderColor: colors.value[colorIndex] })
              colorIndex++
            }
            break
          case 'Week':
            chartData.labels = []
            for (let i = 1; i <= 52; i++) {
              chartData.labels.push('Week ' + i)
              baseData.push(0)
            }
            console.log(data)
            data.forEach(({ _id: { year, term, month, week, day, hour }, count }) => {
              const label = year
              if (!(label in hashByTerm)) {
                hashByTerm[label] = baseData.map((val) => val)
              }
              let index = week - 1
              hashByTerm[label][index] = count
            })
            console.log(hashByTerm)
            for (const label in hashByTerm) {
              chartData.datasets.push({ label, data: hashByTerm[label], borderColor: colors.value[colorIndex] })
              colorIndex++
            }
            break
          case 'RelWeek':
            chartData.labels = []
            for (let i = 1; i <= 18; i++) {
              chartData.labels.push('Week ' + i)
              baseData.push(0)
            }
            data.forEach(({ _id: { year, term, month, week, day, hour }, count }) => {
              const label = (term === '05' ? 'Spring ' : 'Fall ') + year
              if (!(label in hashByTerm)) hashByTerm[label] = baseData.map((val) => val)
              let index = week
              for (const { term: termCode, startWeek } of terms) {
                if (termCode === year + term) {
                  index -= startWeek
                }
              }
              hashByTerm[label][index] = count
            })
            for (const label in hashByTerm) {
              chartData.datasets.push({ label, data: hashByTerm[label], borderColor: colors.value[colorIndex] })
              colorIndex++
            }
            break
          case 'Day':
            chartData.labels = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
            data.forEach(({ _id: { year, term, month, week, day, hour }, count, avg }) => {
              const label = (term === '05' ? 'Spring ' : 'Fall ') + year
              if (!(label in hashByTerm)) hashByTerm[label] = [0, 0, 0, 0, 0]
              hashByTerm[label][day - 2] = average ? avg : count
            })
            for (const label in hashByTerm) {
              chartData.datasets.push({ label, data: hashByTerm[label], borderColor: colors.value[colorIndex] })
              colorIndex++
            }
            break
          default:
            chartData.data = data.map(({ _id: { year, term, month, week, day, hour }, count }) => {
              const label = (term === '05' ? 'Spring ' : 'Fall ') + year
              return { label, value: count }
            })
        }
        loading.value = false
      })
    }

    const average = ref(false)
    watch(average, () => { runReport() })

    const tableHeaders = ref([
      { text: 'Date' },
      { text: 'Received' },
      { text: 'Picked Up' }
    ])

    return {
      tab,
      width,
      height,
      chartOptions,
      colors,
      chartData,
      loading,
      terms,
      selectedTerms,
      toggleTermSelectAll,
      years,
      selectedYears,
      toggleYearSelectAll,
      selectedWeeks,
      weekOptions,
      toggleWeekSelectAll,
      chartType,
      reportType,
      reportOptions,
      period,
      periodOptions,
      runReport,
      average,
      tableHeaders
    }
  }
}
</script>

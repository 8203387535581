var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Mailroom Reports")])],1),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Chart")]),_c('v-tab',[_vm._v("Table")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.periodOptions,"label":"Report Period","outlined":""},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}})],1),_c('v-col',[(_vm.period === 'Month' || _vm.period === 'Week')?_c('v-select',{attrs:{"items":_vm.years,"label":"Year Select","multiple":"","outlined":"","chips":"","small-chips":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggleYearSelectAll}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.selectedYears.length === _vm.years.length ? 'fas fa-times-square' : (_vm.selectedYears.length === 0 ? 'fal fa-square' : 'fas fa-minus-square'))+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Select All Years")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.text))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("(+"+_vm._s(_vm.selectedYears.length - 1)+" others)")]):_vm._e()]}}],null,false,324148295),model:{value:(_vm.selectedYears),callback:function ($$v) {_vm.selectedYears=$$v},expression:"selectedYears"}}):_c('v-select',{attrs:{"items":_vm.terms,"label":"Term Select","multiple":"","outlined":"","chips":"","small-chips":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggleTermSelectAll}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.selectedTerms.length === _vm.terms.length ? 'fas fa-times-square' : (_vm.selectedTerms.length === 0 ? 'fal fa-square' : 'fas fa-minus-square'))+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Select All Terms")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.text))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("(+"+_vm._s(_vm.selectedTerms.length - 1)+" others)")]):_vm._e()]}}]),model:{value:(_vm.selectedTerms),callback:function ($$v) {_vm.selectedTerms=$$v},expression:"selectedTerms"}})],1),(_vm.period === 'Day' || _vm.period === 'Hour')?_c('v-col',[_c('v-select',{attrs:{"items":_vm.weekOptions,"label":"Term Weeks","hint":'Current week: ',"multiple":"","outlined":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"mousedown":function($event){$event.preventDefault();},"click":_vm.toggleWeekSelectAll}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.selectedTerms.length === _vm.terms.length ? 'fas fa-times-square' : (_vm.selectedTerms.length === 0 ? 'fal fa-square' : 'fas fa-minus-square'))+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Select All Weeks")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.text))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("(+"+_vm._s(_vm.selectedWeeks.length - 1)+" others)")]):_vm._e()]}}],null,false,3185109621),model:{value:(_vm.selectedWeeks),callback:function ($$v) {_vm.selectedWeeks=$$v},expression:"selectedWeeks"}})],1):_vm._e(),_c('v-col',[_c('v-select',{attrs:{"items":_vm.reportOptions,"label":"Report Type","outlined":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"margin-top":"-6px"},attrs:{"icon":"","disabled":_vm.reportOptions === '' || _vm.periodOptions === ''},on:{"click":_vm.runReport}},on),[_c('v-icon',[_vm._v("fal fa-running")])],1)]}}])},[_c('span',[_vm._v("Run Report with Selected Options")])])]},proxy:true}]),model:{value:(_vm.reportType),callback:function ($$v) {_vm.reportType=$$v},expression:"reportType"}})],1)],1),(_vm.period === 'Day' || _vm.period === 'Hour')?_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pt-0 mt-0"},[_c('v-switch',{staticClass:"mt-0",attrs:{"label":"Show Average","hide-detail":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('label',{staticClass:"v-label",staticStyle:{"color":"rgba(255, 255, 255, 0.7)","width":"130px"}},[_vm._v("Show Sum (Total)")])]},proxy:true}],null,false,2059493206),model:{value:(_vm.average),callback:function ($$v) {_vm.average=$$v},expression:"average"}})],1)],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":12,"md":"10","offset-md":"1","lg":"8","offset-lg":"2","xl":"6","offset-xl":"3"}},[_c('v-card',{staticClass:"mt-3"},[(_vm.loading)?_c('v-card-text',{staticClass:"pa-10"},[_c('h3',[_vm._v("Loading...")])]):_c('v-card-text',[_c('LineChart',{attrs:{"chartData":_vm.chartData,"options":_vm.chartOptions,"height":60,"width":80}})],1)],1)],1)],1)],1),_c('v-tab-item',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }